import consumerApi from "api/services/consumer-api";
import { MenuItem } from "custom-types/MenuItem";
import formatImgixUrl from "utils/image/formatImgixUrl";
import logError from "utils/logError";

export const mapMenuItemImages = (menuItem: MenuItem) => {
  return {
    ...menuItem,
    imageUrl: menuItem.imageUrl && formatImgixUrl(menuItem.imageUrl),
    thumbnailUrl: formatImgixUrl(menuItem.formattedThumbnailUrl),
  };
};

const getMenuItem = async (
  menuItemId: number,
  countryCode?: string,
): Promise<MenuItem | null> => {
  try {
    const { data } = await consumerApi.get<MenuItem>(
      `/api/menu_items/v1/${menuItemId}`,
      {
        headers: {
          "x-country-code": countryCode || "US",
        },
      },
    );

    return mapMenuItemImages(data);
  } catch (e) {
    if (e.statusCode !== 404) {
      logError(e.message, {
        functionName: "getMenuItem",
        service: "consumer",
        statusCode: e.statusCode,
      });
    }

    return null;
  }
};

export default getMenuItem;
